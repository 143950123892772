import React from 'react'
import Logout from '../pages/authentication/Logout'
import { useDispatch, useSelector } from 'react-redux'
import { setIsOpen, setWidth } from '../features/slices/sidebarSlice';
import MenuIcon from '@mui/icons-material/Menu';
interface NavBarProps {
  sidebarOpen: string | boolean | undefined
  setSidebarOpen: (arg0: boolean) => void
}

const Navbar: React.FC<NavBarProps> = ({setSidebarOpen, sidebarOpen }: NavBarProps) => {
  const { isLoggedIn } = useSelector((store: any) => store.users)

  const isSidebarOpen = useSelector((state: any) => state.sidebar.isOpen)
  
  const dispatch = useDispatch()

  const handleSidebarButton = () => {
    dispatch(setWidth('w-72.5'))
    dispatch(setIsOpen(true))
    setSidebarOpen(true)
  }

  return (
    <header className='sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none'>
      <div className='flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11'>
          {!isSidebarOpen ? <MenuIcon onClick={handleSidebarButton}/> : null}
        {isLoggedIn && <Logout />}
      </div>
    </header>
  )
}

export default Navbar
