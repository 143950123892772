import { createSlice } from '@reduxjs/toolkit'

export interface IState {
  width: any
  isOpen: boolean
  layout: any
}

const initialState: IState = {
  width: 'w-72.5',
  isOpen: true,
  layout: 'default',
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setWidth: (state, action) => {
      state.width = action.payload
    },
    setIsOpen: (state, action) => {
      state.isOpen = action.payload
    },
    setLayout: (state, action) => {
      state.layout = action.payload
    },
  },
})

export const { setWidth, setIsOpen, setLayout } = sidebarSlice.actions
export default sidebarSlice.reducer
