import { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PacmanLoader, SyncLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/material'

const mdmUrl = process.env.REACT_APP_BASE_URL

const NewRegistration = (): JSX.Element => {
  const navigate = useNavigate()
  const [tempPass, settempPass] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const { token } = useParams()

  console.log('token: ', token)

  const dispatch = useDispatch()
  const { isLoggedIn, X_Authorization } = useSelector(
    (store: any) => store.users,
  )

  useEffect(() => {
    if (isLoggedIn) navigate('/dashboard')
  }, [isLoggedIn])

  // Separate function for form validation
  const validateForm = () => {
    return (
      tempPass.trim() !== '' &&
      password.trim() !== '' &&
      username.trim() !== '' &&
      confirmPassword.trim() !== ''
    )
  }

  // Function to handle user login
  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSubmitted(true)

    if (!validateForm()) {
      toast.error('Please fill in all fields', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'login-toast',
        theme: 'colored',
      })
      return
    }

    if (password.trim() !== confirmPassword.trim()) {
      toast.error('Passwords do not march!', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'login-toast',
        theme: 'colored',
      })
      return
    }

    setLoading(true)
    try {
      // Perform the login if captcha is valid
      const response = await fetch(`${mdmUrl}/api/set-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tempPass, password, username, token }),
      })
      const data = await response.json()

      console.log("data", data)

      if (data.status === 0) {
        toast.success(`${data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'user-toast',
          theme: 'colored',
        })
        navigate('/')
      } else {
        toast.error(`${data.error}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'login-toast',
          theme: 'colored',
        })
      }
    } catch (err) {
      console.error('Login error:', err)
      toast.error('Reset password failed. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'login-toast',
        theme: 'colored',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!isLoggedIn && (
        <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
          <ToastContainer />
          <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
            <Stack width={'100%'} alignItems={'center'} my={2}>
              <NavLink
                to=''
                className='bg-gradient-to-r p-5 rounded-full w-20 h-20 flex justify-center items-center from-black from-50% via-sky-500 via-20% to-emerald-500 to-100%'
              >
                <h1 className='text-white font-bold'>MDM</h1>
              </NavLink>
            </Stack>
            <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
              Set your Password
            </h2>
          </div>
          <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm shadow-2xl p-10'>
            <form className='space-y-6' onSubmit={handleLogin}>
              {/* username Input */}
              <div>
                <label
                  htmlFor='username'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Username
                </label>
                <div className='mt-2'>
                  <input
                    id='username'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    type='text'
                    autoComplete='username'
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:ring-2 sm:text-sm sm:leading-6
                    ${submitted && username.trim() === '' ? 'border-red-500' : 'border-0'}`}
                  />
                </div>
              </div>
              {/* tempPass Input */}
              <div>
                <label
                  htmlFor='tempPass'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Temporary Password
                </label>
                <div className='mt-2'>
                  <input
                    id='tempPass'
                    value={tempPass}
                    onChange={(e) => settempPass(e.target.value)}
                    type='text'
                    autoComplete='tempPass'
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:ring-2 sm:text-sm sm:leading-6
                    ${submitted && tempPass.trim() === '' ? 'border-red-500' : 'border-0'}`}
                  />
                </div>
              </div>

              {/* Password Input */}
              <div>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  New Password
                </label>
                <div className='mt-2'>
                  <input
                    id='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type='password'
                    autoComplete='current-password'
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:ring-2 sm:text-sm sm:leading-6
                    ${submitted && password.trim() === '' ? 'border-red-500' : 'border-0'}`}
                  />
                </div>
              </div>

              {/* Confirm Password  */}
              <div>
                <label
                  htmlFor='confirmPassword'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Confirm Password
                </label>
                <div className='mt-2'>
                  <input
                    id='confirmPassword'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type='password'
                    autoComplete='current-password'
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:ring-2 sm:text-sm sm:leading-6
                    ${submitted && confirmPassword.trim() === '' ? 'border-red-500' : 'border-0'}`}
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md bg-black px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                >
                  {loading ? <PacmanLoader color='#fff' /> : 'Sign in'}
                </button>
              </div>
            </form>

            <p className='mt-10 text-center text-sm font-medium text-gray-500'>
              Not Registered?
              <NavLink
                to='/createAccount'
                className='ml-2 font-semibold leading-6 text-indigo-600 hover:text-indigo-500'
              >
                Create Account
              </NavLink>
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default NewRegistration
