// DeviceMap.tsx
import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { useSelector } from 'react-redux'
import { PuffLoader } from 'react-spinners'
import { toast } from 'react-toastify'

const mdmUrl = process.env.REACT_APP_BASE_URL

// Fix for marker icon not displaying correctly in Leaflet
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

// Define the type for a device's coordinate data
interface DevicePosition {
  latitude: number
  longitude: number
  pn: string
  sn: string
  timestamp: string
}

const DeviceMap: React.FC = () => {
  const { X_Authorization } = useSelector((state: any) => state.users)

  const [loading, setLoading] = useState(false)
  const [coordinates, setCoordinates] = useState<DevicePosition[]>([
    {
      latitude: 0,
      longitude: 0,
      pn: 'N/A',
      sn: 'N/A',
      timestamp: 'N/A',
    },
    {
      latitude: 0,
      longitude: 0,
      pn: 'N/A',
      sn: 'N/A',
      timestamp: 'N/A',
    },
  ])

  const getDevicePositions = async () => {
    setLoading(true)
    try {
      const res = await fetch(
        `${mdmUrl}/api/swipe/es/device-positions/search`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${X_Authorization}`,
          },
        },
      )

      const data = await res.json()
      if (data.error) {
        console.log('data error', data.error)
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'error-toast',
          theme: 'colored',
        })
      } else {
        console.log('fetched coordinates', data)
        setCoordinates(data)
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  useEffect(() => {
    getDevicePositions()
  }, [])

  console.log('coordinates', coordinates)

  return (
    <>
      {loading ? (
        <i className='flex justify-center items-center w-full mt-20'>
          <PuffLoader color='#007aff' />
        </i>
      ) : (
        <MapContainer
          center={
            coordinates.length > 0
              ? [coordinates[0].latitude, coordinates[0].longitude]
              : [0, 0]
          } // Default center if no coordinates
          zoom={16}
          style={{ height: '500px', width: '100%' }}
        >
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {coordinates.map((device, index) => (
            <Marker key={index} position={[device.latitude, device.longitude]}>
              <Popup>
                <div>
                  <strong>SN: </strong>
                  {device.sn}
                  <br />
                  <strong>PN: </strong>
                  {device.pn}
                  <br />
                  <strong>Timestamp: </strong>
                  {device.timestamp}
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      )}
    </>
  )
}

export default DeviceMap
