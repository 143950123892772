import Chart from 'react-apexcharts'

interface ChartProps {
  active: any
  inActive: any
}

const AppsDonutChart = ({ active, inActive }: ChartProps) => {
  const data = {
    series: [active, inActive],
    chartOptions: {
      labels: ['Active', 'Inactive'],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function (w: any) {
                  return w.globals.seriesTotals.reduce((a: any, b: any) => {
                    return a + b
                  }, 0)
                },
              },
            },
          },
        },
      },
    },
  }

  return (
    <div className='donut'>
      <Chart
        options={data.chartOptions}
        series={data.series}
        type='donut'
        width='380'
      />
    </div>
  )
}

export default AppsDonutChart
