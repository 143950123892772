import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setLayout } from '../features/slices/sidebarSlice'
import { Stack } from '@mui/material'

const steps = [
  'Create Model',
  'Create Group',
  'Create Institution and activate',
  'Create Device',
  'Upload and Approve App',
  'Add Device and App To Group',
  'Approve Device Group',
  'Create Task',
  'Approve Task',
]

const StepContent = ({ step }: { step: number }) => {
  switch (step) {
    case 0:
      return (
        <Typography>
          Create model  or  ensure it exists
        </Typography>
      )
    case 1:
      return (
        <Typography>
          Create Group or ensure it exists and is active
        </Typography>
      )
    case 2:
      return <Typography>Create Institution and activate</Typography>
    case 3:
      return (
        <Typography>
          Create Device or ensure it exists and is active
        </Typography>
      )
    case 4:
      return <Typography>Upload and Publish App</Typography>
    case 5:
      return (
        <Typography>
          Select Group Add a device Add an App
        </Typography>
      )
    case 6:
      return (
        <Typography>
          Select Group Ensure the Device Group status is activated
        </Typography>
      )
    case 7:
      return (
        <Typography>
          Create a Task - Ensure you select the relevant App and Terminal Group
        </Typography>
      )
    case 8:
      return <Typography>Approve Task</Typography>
    default:
      return null
  }
}

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isStepOptional = (step: number) => {
    return step === 13
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const navigator = () => {
    if (activeStep === 0) {
      navigate('/dashboard/device/model')
    } else if (activeStep === 1) {
      navigate('/dashboard/device/group')
    } else if (activeStep === 2) {
      navigate('/dashboard/device/institution')
    } else if (activeStep === 3) {
      navigate('/dashboard/devices')
    } else if (activeStep === 4) {
      navigate('/dashboard/app-store/app-catalogue')
    } else if (activeStep === 5) {
      navigate('/dashboard/device/group')
    } else if (activeStep === 6) {
      navigate('/dashboard/device/group')
    } else if (activeStep === 7) {
      navigate('/dashboard/app-store/add-task')
    } else if (activeStep === 8) {
      navigate('/dashboard/app-store/app-distribution')
    }
  }

  React.useEffect(() => {
    navigator()
  }, [activeStep])

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant='caption'>Optional</Typography>
            )
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - Task Created
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
            <Button
              onClick={() => {
                dispatch(setLayout('default'))
                navigate('/dashboard/app-store/app-distribution')
              }}
            >
              Exit
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 3, mb: 1, ml: 3, fontWeight: 'bold' }}>Step {activeStep + 1}</Typography>
          {/* Render specific content for each step */}
          <Stack sx={{ml:10}}>
            <StepContent step={activeStep} />
          </Stack>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button
              color='inherit'
              onClick={() => {
                dispatch(setLayout('default'))
                navigate('/dashboard/app-store/app-distribution')
              }}
              sx={{ mr: 1 }}
            >
              Exit
            </Button>
            <Button
              color='inherit'
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            {isStepOptional(activeStep) && (
              <Button color='inherit' onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  )
}
