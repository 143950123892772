import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'
import FloatingButton from '../pages/tests/FloatingButton'
import { Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import TestPage from '../pages/tests/TestPage'
import HorizontalLinearStepper from '../components/Stepper'

const TasksLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true)

  const sidebarState = useSelector((state: any) => state.sidebar.isOpen)

  useEffect(() => {
    if (sidebarState === true) {
      console.log('big now')
      setSidebarOpen(true)
    } else if (sidebarState === false) {
      setSidebarOpen(false)
      console.log('small now')
    }
  }, [sidebarState])

  return (
    <div className='dark:bg-boxdark-2 dark:text-bodydark'>
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className='flex h-screen overflow-hidden'>
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className='relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden'>
          {/* <!-- ===== Header Start ===== --> */}
          <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* <!-- ===== Header End ===== --> */}

          {/* testing floating button*/}

          {!sidebarOpen && (
            <Stack
              position={'absolute'}
              top={100}
              left={100}
              display={{ xs:'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' }}
            >
              <FloatingButton />
            </Stack>
          )}

          {/* end of floating button*/}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className='mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10'>
              {/* <div className="mx-auto max-w-screen-2xl p-4 "> */}
              <HorizontalLinearStepper />
              <Outlet />
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  )
}

export default TasksLayout
