import { Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DevicesDonutChart from "./DevicesDonutChart";

const mdmUrl = process.env.REACT_APP_BASE_URL

const DeviceCount = () =>{
    const [loading, setLoading] = useState(false)
    const [activeDevices, setActiveDevices] = useState(0)
    const [inActiveDevices, setInactiveDevices] = useState(0)
  
    const { X_Authorization } = useSelector((state: any) => state.users)
  
    const getDevices = async () => {
      setLoading(true)
      try {
        const res = await fetch(`${mdmUrl}/api/swipe/devices?size=50&start=0`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${X_Authorization}`,
          },
        })
        const data = await res.json()
        console.log(data)
        if (data.status === 0) {
          let fetchedDevices = data.data
          let active: any = []
          let inActive: any = []
          fetchedDevices.map((device: any) => {
            if (device.status === 1) {
              active.push(device)
            } else if (device.status === 2) {
              inActive.push(device)
            }
          })
          setActiveDevices(active.length)
          setInactiveDevices(inActive.length)
          setLoading(false)
          console.log(data)
        } else {
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
  
    useEffect(() => {
      getDevices()
    }, [])

    return(
        <Stack mt={2} style={{ height:'100%'}}>
        <Paper elevation={2} style={{height:'100%'}}>
          <Stack p={2}>
            <Typography>Number of devices</Typography>
            <Stack className='mt-4 grid place-items-center px-2'>
              <DevicesDonutChart active={activeDevices} inActive={inActiveDevices}/>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    )
}

export default DeviceCount;
