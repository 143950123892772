import { Stack, Typography } from '@mui/material'
import AppCount from '../../components/AppCount'
import DeviceCount from '../../components/DeviceCount'
import DeviceMap from '../../components/DeviceMap'
import UsersCount from '../../components/UsersCount'

const ResentVisits: React.FC = () => {
  return (
    <div className='flex w-full flex-col'>
      <h1 className='text-xl text-body'>Dashboard</h1>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 mt-5 md:mt-10 border-2 border-gray rounded p-3 md:p-5 bg-gray-200'>
        <div className='flex flex-col w-full'>
          <h3 className='text-normal font-bold'>Apps</h3>
          <AppCount />
        </div>
        <div className='flex flex-col w-full'>
          <h3 className='text-normal font-bold'>Devices</h3>
          <DeviceCount />
        </div>
        <div className='flex flex-col w-full'>
          <h3 className='text-normal font-bold'>Users</h3>
          <UsersCount />
        </div>
      </div>

      <Stack>
        <Stack my={2}>
          <h1 className='text-xl text-body'>Device Locations</h1>
        </Stack>
        <DeviceMap />
      </Stack>
    </div>
  )
}

export default ResentVisits